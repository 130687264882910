import React from "react";

import Layout from "./layout";
import SEO from "./seo";
import Section from './Section';
import Home from './Home';

// import imgPage2 from '../../images/page2.png';
// import imgPage3 from '../../images/page3.png';
// import imgPage4 from '../../images/page4.png';
// import imgPage5 from '../../images/page5.png';
// import imgPage6 from '../../images/page6.png';
// import imgPage7 from '../../images/page7.png';
// import imgPage8 from '../../images/page8.png';
import imgHandbook from '../../images/manual.png';
import statistics from '../../images/statistics.png';
import payment from '../../images/notification.png';
import home from '../../images/handbook.png';
import profile from '../../images/profile.png';

// import page2traffic from '../../images/icons/lights.svg';
// import page2direction from '../../images/icons/direction.svg';
// import page3man from '../../images/icons/man.png';
// import page4camera from '../../images/icons/camera.svg';
// import page4phone from '../../images/icons/phone.svg';
// import page4smile from '../../images/icons/smile.svg';
// import page5fance from '../../images/icons/fance.svg';
// import page5suit from '../../images/icons/suit.png';
import page6car from '../../images/icons/car.svg';
import page6key from '../../images/icons/key.svg';
import page7bell from '../../images/icons/bell.svg';
import page8chart from '../../images/icons/chart.svg';

import { t } from '../../lang';

const IndexPage = (props) => {
  let lang = 'uz';
  if (typeof window !== `undefined`) {
    lang = window.location.pathname.replace(/\//g, '') || 'uz'
  }

  return (
    <Layout location={props.location} lang={lang}>
      <SEO
        lang={lang}
        title={t("Сделаем дороги безопасными вместе")}
        description={t("Приложение для проверки и оплаты штрафов в Узбекистане. Отправка видео нарушений, отправка проблем на дороге, дорожные работы, статистика ДТП, проверка участие авто в ДТП, сервисная книжка автомобиля.")}
      />
      <Home />

      <Section
        id="profile"
        key="profile"
        img={profile}
        title={t("Анкета автомобилиста")}
        titleImg={[page6car, page6key]}
        list={[
          t("Jarimalarni tekshirish va ogohlantirish"),
          t("DYHXX dan texnik ko'rik va tonirovka holatini ko'rish"),
          // t("Sug‘urta to‘lovlarini kafolatlash jamg‘armasi dan - sugurta holati (tez kunda)"),
          t("Avtomobil tarixini ko'rish imkoniyati(tez kunda)"),
          t("Road24 orqali Sug'urta  va tonirovka sotib olish (tez kunda)"),
          t("Adliya vazirligi orqali ishonchnoma olish")
        ]}
        textImg={[]}
      />
      <Section
        bg
        id="payment"
        key="payment"
        img={payment}
        title={t("Мониторте и оплачивайте штрафы")}
        titleImg={[page7bell]}
        list={[
          t("Qoidabuzarlik deb topilgan holatni foto va video yozuvni ko'rish"),
          // t("Jarimalarni qulay tarzda va 30% chegirma bilan to'lash"),
          t("Jarimani mos ravishda belgilangan muddatlar oralig'ida 50% , 30% chegirma bilan to'lash"),
          t("Chegirma tugashidan 7 va 2 kun o'ldin push-ogohlantirish yuborish"),
          t("Qoidabuzarlik sodir etilgan joyni xarita orqali ko'rish"),
          t("Jarimani hech qanday qo'shimcha ustamalarsiz to'lash"),
        ]}
        textImg={[]}
      />

      <Section
        id="statistics"
        key="statistics"
        img={statistics}
        title={t("Просматривайте статистику ДТП по всему Узбекистану")}
        titleImg={[page8chart]}
        list={[
          t("DYHXX dan avtomobil YTHga uchraganligini tekshirish (tez kunda)"),
          t("YTH o‘choqlari yuzasidan ochiq ma'lumotlar"),
        ]}
        textImg={[]}
      />

      <Section
        bg
        id="handbook"
        key="handbook"
        img={imgHandbook}
        title={t("Haydovchilar uchun eng mukammal yo'riqnoma")}
        titleImg={[]}
        list={[
          t("Ma'muriy jarimalar to'g'risidagi Kodeksning transport vositalariga tegishli moddalari."),
          t("Yo'l harakat qoidalari elektron kitobi"),
          t("Hususiy texnik ko'rik tashkilot manzillari"),
          t("Avtomovil ro'xatga olish va imtxon topshirish manzillari"),
          t("Ma'muiry amaloyot bo'limlar va SUDlar manzillar (tez kunda)")
        ]}
        textImg={[]}
      />
    </Layout>
  )
}

export default IndexPage
