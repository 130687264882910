import React from 'react';
import logo from '../../images/icon.svg';
import { StyledFooter } from '../../styles';
import iconKashApp from '../../images/kash-app-white.svg';
import DownloadSection from './DownloadSection';
import { t } from '../../lang';
import agreements from '../../docs/1.pdf';
import agreements_ru from '../../docs/1_ru.pdf';
import publicOffering from '../../docs/2.pdf';
import policy from '../../docs/3.pdf';

export default function Footer() {
  const openLink = (order) => {
      let lang = '';
      if (typeof window !== `undefined`) {
          lang = window.location.pathname.replace(/\//g, '');
      }

      const opener = (link) => {
          window.open(link, '_blank');
      }

      switch (order) {
          case 1:
              if (lang === 'uz') {
                  opener(agreements);
              } else if (lang === 'ru') {
                  opener(agreements_ru);
              } else {
                  opener(agreements);
              }
              break;
          case 2:
              if (lang === 'uz') {
                  opener(publicOffering);
              } else if (lang === 'ru') {
                  opener(publicOffering);
              } else {
                  opener(publicOffering);
              }
              break;
          case 3:
              if (lang === 'uz') {
                  opener(policy);
              } else if (lang === 'ru') {
                  opener(policy);
              } else {
                  opener(policy);
              }
              break;
          default:
              break;
      }
  }
  return (
    <StyledFooter>
      <div className="container">
        <img src={logo} alt="road24.uz" />
        <h1>{t("Road24 находится в разработке")}</h1>
        <DownloadSection id="footer" key="footer-section" />
        <a href="https://t.me/road24uz" id="telegram">{t("Следите за обновлениями Road24 в Telegram")}</a>
      </div>
      <div id="lower-footer">
        <img src={iconKashApp} alt="OOO Kash App" />
        <a href="https://kash.uz/">{t("developed_by")}</a>
      </div>
        <a href="#" onClick={() => openLink(1)} style={{ margin: 10, height: 20, display: 'inline-block' }}>{t("agreements")}</a>
        <a href="#" onClick={() => openLink(2)} style={{ margin: 10, height: 20, display: 'inline-block' }}>{t("public_offering")}</a>
        <a href="#" onClick={() => openLink(3)} style={{ margin: 10, height: 20, display: 'inline-block' }}>{t("partner_request")}</a>
    </StyledFooter>
  )
}
