import React, { useCallback, useState } from 'react';
import { StyledDownloadSection } from '../../styles';
import ReactCardFlip from 'react-card-flip';
import { addPhoneOrEmail, inputValidator } from '../../utils';
import Message from './message';
import Swal from 'sweetalert2';
import spinnerWhite from '../../images/spinner-white.svg';
import { t } from '../../lang';
import iconGoogle from '../../images/google.svg';
import iconApple from '../../images/apple.svg';
import iconGoogleWhite from '../../images/google-white.svg';
import iconAppleWhite from '../../images/apple-white.svg';

const APP_STORE_LINK = 'https://apps.apple.com/uz/app/road24/id1543010525?utm_source=landinPage&utm_medium=hyperlink';
const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=uz.kashapp.road24jarimalar';


export default function DownloadSection(props) {
  const [isFlipped, setIsFlipped] = useState(true);
  const [value, setValue] = useState('');
  const [message, setMessage] = useState({ type: null, text: '' });
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setIsFlipped(state => !state);
  }, [setIsFlipped]);

  const addValue = useCallback(() => {

    async function createValue() {
      setLoading(true);
      const validation = inputValidator(value);
      setMessage(validation);
      if (validation.type) {
        const sendToServer = await addPhoneOrEmail(value, validation.type);
        if (sendToServer.success) {
          Swal.fire({
            // text: validation.text,
            // title: t('Успешно'),
            icon: 'success',
            timer: 10000,
            html: validation.text
          });
          setValue('');
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    return createValue();
  }, [value, setValue]);


  return (
    <StyledDownloadSection id={props.id}>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <div>
          <p>{t('Оставьте свой контакт, и будьте первым, кто узнает о запуске проекта')}</p>
          {message && <Message data={message} />}
          <div id="input-section" className="download-item subscribe">
            <input
              type="text"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              onKeyPress={(e) => {
                console.log(e.key);
                if (e.key === 'Enter') {
                  return addValue()
                }
              }}
              placeholder={t("ваш email или телефон")} />
            <button onClick={addValue}>
              {loading && <img src={spinnerWhite} alt="spinner" width="20" />}
              {t("Уведомить меня")}
            </button>
          </div>
        </div>
        <div>
          {!props.id ?
            <ul className="home__list">
              <li>{t("Фото и видео нарушения")}</li>
              <li>{t("Jarimani mos ravishda belgilangan muddatlar oralig'ida 50% , 30% chegirma bilan to'lash")}</li>
              <li>{t("Уведомление о штрафах")}</li>
              <li>{t("Jarimani hech qanday qo'shimcha ustamalarsiz to'lash")}</li>
            </ul>
            :
            null
          }
          <div id="app-store" className="download-item app-store">
            <a href={PLAY_STORE_LINK} target="_blank" className="app-button">
              <img src={props.id ? iconGoogleWhite : iconGoogle} alt="Google play store road24" />
            </a>
            <a href={APP_STORE_LINK} target="_blank" className="app-button">
              <img src={props.id ? iconAppleWhite : iconApple} alt="Apple app store road24" />
            </a>
          </div>
        </div>

      </ReactCardFlip>
    </StyledDownloadSection>
  )
}
